<template>
  <OnboardingStep
    :title="$t('onboarding.nightlyReportsIntroView.title')"
    :next-text="$t('onboarding.nightlyReportsIntroView.nextText')"
    v-bind="$props"
  >
    <I18nArray tag="p" path="onboarding.nightlyReportsIntroView.paragraphs" />
  </OnboardingStep>
</template>

<script>
import { BaseStepView } from "./BaseStepView";
import I18nArray from "@/components/I18nArray";

export default {
  name: "NightlyReportsIntroView",
  components: { I18nArray },
  extends: BaseStepView,
};
</script>
